<template>
  <b-table
    :data="[data.data]"
    :striped="true"
    :narrowed="true"
  >
    <b-table-column :label="data.data.labelTest">
      {{ data.data.conclusion }}
    </b-table-column>
    <b-table-column :label="data.data.labelStatistic">
      <template v-slot:header>
        <b-tooltip v-if="'labelStatisticShort' in data.data"
          :label="data.data.labelStatistic" append-to-body dashed
        >{{ data.data.labelStatisticShort }}</b-tooltip>
        <span v-else>{{ data.data.labelStatistic }}</span>
      </template>
      {{ data.data.statistic }}
    </b-table-column>
    <b-table-column :label="data.data.labelPvalue" numeric>
      {{ data.data.pvalue | scientific }}
    </b-table-column>
    <b-table-column width="75">
      <statistical-test-stars :score="data.data.stars" />
    </b-table-column>
  </b-table>
</template>

<script>
export default {
  name: 'BasicTest',
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  components: {
    'statistical-test-stars': () => import('@/components/ui/StatisticalTestStars.vue')
  }
}
</script>
